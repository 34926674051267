// bootstrap overrides and a few more: ***********************************
// colors:
$primary: #33bef5 !default;
$primary-light: #c0ebfc !default;
$primary-lightest: lighten($primary-light, 25%) !default;
$marker-bg-color: #52bfff !default;
$btn-hover-color: #ca480e !default;
$btn-hover-border-color: #be440e !default;
$black: #0e0b09 !default;
$bg-black: #0e0b09 !default;
$dark: rgba(14, 11, 9, 0.95) !default; // #0E0B09
$appLightBlack: #181818;
$dark-opac: rgba(14, 11, 9, 0.85);
$gray: #747980 !default;
$footer-gray: $gray !default;
$light: #ffffff;
$white: #ffffff;
$white2: rgba(116, 121, 128, 0.05); // TODO: hex
$white-overlay: rgba(255, 255, 255, 0.95);
$dark-transparent: rgba(0, 0, 0, 0.6);
$dark-transparent-hover: rgba(0, 0, 0, 0.5);
$light-outline-color: rgba(238, 238, 238, 0.1);
$ui-box-shadow: 2px 4px 20px #000000;
$psLogoColor: #87cbe7;
$logoCollapseHeight: 120px !default;
$arrowUrl: "../assets/icons/iconmonstr-arrow-71-24.png" !default;

$blue: #1968af !default;
$red: #ee1111 !default;
$orange: #ff6f2f !default; // #fd7e14 !default;
$yellow: #ee9211; // #ffc107 !default;
$green: #0ba168; // #28a745 !default;
$material-error: #f44336;

$gray-100: #f8f9fa !default;
$gray-150: #f2f5fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;

$link-color: $primary;
$link-hover-color: $gray;
$border-color: #eeeeee;

// typography
$font-family-sans-serif: "Baloo Tammudu 2", cursive;
$font-family-text: $font-family-sans-serif;
$font-family-cursive: "Pangolin", cursive;
$headings-font-family: $font-family-sans-serif;
$link-decoration: none;
$link-hover-decoration: none;
$input-placeholder-color: $gray-500 !default;
// $font-family-base: $font-family-text;
// http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
// $font-size-base: calc(12px + 0.1vw);
$font-size-base: 1rem; // 16px
$font-size-md: 0.8rem; // 14px = 0.875rem
$font-size-md-2: 0.875rem;
$font-size-sm: 0.73125rem;
// $font-size-small: 0.7875rem;
$font-size-xs: 0.73125rem;
$font-size-xxs: 0.65rem;
$font-size-xxxs: 0.542rem;
$h2-font-size: 1.17rem;
$h3-font-size: 0.99rem;
$h4-font-size: 0.8rem;
$h5-font-size: 0.765rem;

//navbar
$navbar-height: auto;
$navbar-padding-y: 0px;
$nav-link-padding-y: 0;
// $navbar-brand-padding-y
$navbar-logo-width-mobile: 80px !default;
$navbar-logo-height-mobile: 80px !default;
$navbar-logo-auth-left: 0px !default;
$navbar-logo-auth-top: 0px !default;

// $grid-gutter-width: 50px;
$form-grid-gutter-width: 0px; // was 10

// form controls
$border-radius: 0px;
$input-btn-font-size: $font-size-md; //$font-size-base;
$input-font-size: $font-size-md; // $font-size-base; //16px; // needs to be 16px so iphone doesn't focus in

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px,
) !default;

// end bootstrap override: ***********************************

$checkmarkUrl: "../assets/icons/iconmonstr-check-mark-1-24.png" !default;

// grid utitlities
@mixin style-layout-mobile() {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin style-layout-xs() {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin style-layout-xxs() {
  @media (max-width: 350px) {
    @content;
  }
}

@mixin style-layout-desktop() {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin style-layout-tablet-desktop() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin style-layout-tablet-mobile() {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin style-layout-lg-up() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin style-layout-xl-up() {
  @media (min-width: 1200px) {
    @content;
  }
}

// @include media-breakpoint-up(sm) {
// 	// Styles
//   }

// Mixin to prefix several properties at once
/// @param {Map} $declarations - Declarations to prefix
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}

@mixin transform($props...) {
  @include cross-browser-property(transform, $props);
}

@mixin cross-browser-property($prop-name, $value...) {
  @each $browser in "moz", "webkit", "o", "ms" {
    -#{$browser}-#{$prop-name}: $value;
  }
  $prop-name: $value;
}

@mixin box-shadow() {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
}

@mixin showLoader() {
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 52%;
    left: 83%;
    width: 28px;
    height: 28px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 1px solid #ccc;
    border-top-color: $black;
    animation: spinner 0.6s linear infinite;
  }
}

@mixin rotate() {
  animation: spinInPlace 1.8s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes spinInPlace {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 28px 0;
  }
}

@keyframes move-down {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  30%,
  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

$os-scrollbar-radius: 2px;
@mixin os-scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar:hover {
    // background: $primary-lightest;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: $os-scrollbar-radius;
    border-radius: $os-scrollbar-radius;
    // background: rgba(0, 0, 0, 0.1);
    background: $background-color;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: $os-scrollbar-radius;
    border-radius: $os-scrollbar-radius;
    // background: rgba(0, 0, 0, 0.2);
    background: $foreground-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    // background: rgba(0, 0, 0, 0.4);
    background-color: $orange;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    // background-color: rgba(0, 0, 0, 0.05);
    // background-color: $gray;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

// @mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
// 	// For Google Chrome
// 	&::-webkit-scrollbar {
// 		width: $size;
// 		height: $size;
// 	}

// 	// &::-webkit-scrollbar-thumb {
// 	// 	background: $foreground-color;
// 	// }

// 	// &::-webkit-scrollbar-track {
// 	// 	background: $background-color;
// 	// }

// 	// // For Internet Explorer
// 	// & {
// 	// 	scrollbar-face-color: $foreground-color;
// 	// 	scrollbar-track-color: $background-color;
// 	// }
// }

@mixin placeholder() {
  ::-webkit-input-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
  ::placeholder {
    @content;
  }
}

@mixin progress-bar-background() {
  width: 100%;
  border: 1px solid #fff;
  background-image: repeating-linear-gradient(
    -45deg,
    $gray-100,
    $gray-100 11px,
    #fff 10px,
    #fff 20px
  );
  background-size: 28px 28px;
  animation: move 0.5s linear infinite;
  text-transform: uppercase;

  .loading-text {
    font-family: $font-family-sans-serif !important;
    font-size: 12px !important;
    span {
      animation-name: blink;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      /* animation-delay: 2s; */

      &:nth-of-type(2n) {
        animation-delay: 200ms;
      }
      &:nth-of-type(3n) {
        animation-delay: 400ms;
      }
    }
  }
}

@mixin desktop-horizontal-padding() {
  @include style-layout-desktop() {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

// must set width
@mixin text-overflow() {
  display: inline-block;
  min-width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin divider() {
  content: "";
  position: absolute;
  height: 1px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.12);
}

@mixin top-divider() {
  &:before {
    @include divider();
    top: -1px;
    @content;
  }
}

@mixin bottom-divider() {
  &:after {
    @include divider();
    bottom: -1px;
    @content;
  }
}

@mixin arrow-up($color) {
  display: inline-block;
  border-top: 0px none;
  border-left: 0.3rem solid transparent;
  border-bottom: 0.5rem solid $color;
  border-right: 0.3rem solid transparent;
  margin-right: 0.31rem;
  margin-bottom: 0px;
}

@mixin arrow-down($color) {
  display: inline-block;
  border-top: 0.5rem solid $color;
  border-left: 0.3rem solid transparent;
  border-bottom: 0px none;
  border-right: 0.3rem solid transparent;
  margin-right: 0.31rem;
  margin-bottom: -1px;
}

@import "ios-viewport-fix";

@mixin filter($value) {
  -webkit-filter: $value;
  filter: $value;
}

@mixin text-selection-color() {
  &::-moz-selection {
    background: $primary-lightest !important;
  }
  &::selection {
    background: $primary-lightest !important;
  }
}
