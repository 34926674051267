@import "../../styles/app_variables.scss";

h1.logo {
  font-size: 56px;
  font-family: $font-family-cursive;
  color: $primary;
}

.logo-container {
  text-align: center;
  margin: 40vh auto;

  .mail-link {
    font-size: 12px;
  }
}
